import AppContent from './MainAppComponents/AppContent';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import theme from './Config/Themes/config';
import './Config/Validation/yup.config';
import ToastContainer from './Common/Toast/ToastContainer';

function App() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <AppContent />
            </ThemeProvider>
            <ToastContainer />
        </>
    );
}

export default App;
