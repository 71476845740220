import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconChevronRight } from '@tabler/icons';

import { drawerWidth } from '../Config/constant';
import Header from './Header';
import { useThemeStore } from '../Stores/ThemeStore';
import Sidebar from './Sidebar';
import { menuItems } from './Sidebar/menu-items';
import Breadcrumbs from '../Components/Theme/Extended/Breadcrumbs';
import { useAuthStore } from '../Stores/AuthStore';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

const Authenticated = ({ children }) => {
    const authStore = useAuthStore();
    const theme = useTheme();
    const themeStore = useThemeStore();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const menu = useMemo(() => menuItems(authStore), [authStore.authUser]);

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        !matchDownMd ? themeStore.openMenu() : themeStore.closeMenu();
    }, [themeStore, matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: themeStore.isMenuOpen ? theme.transitions.create('width') : 'none',
                }}
            >
                <Toolbar className="py-0">
                    <Header />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={themeStore.isMenuOpen}>
                <Breadcrumbs separator={IconChevronRight} navigation={menu} icon title rightAlign />
                {children}
            </Main>
        </Box>
    );
};

export default observer(Authenticated);
