import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import { createContext, useContext } from 'react';
import {
    getProfile,
    login,
    updateProfile as updateProfileRequest,
    changePassword,
    forgotPassword,
    resetPassword,
    refreshToken as refreshTokenRequest,
    getVerificationMail,
    verifyMail
} from '../Api/AuthAgent';
import { setBearerToken } from '../Common/HttpClient/HttpClient';
import { decodeJwtToken } from '../Common/Encryption/Encryption';
import { success } from '../Common/Toast/Toast';
import {
    getRefreshTokenFromLocalStorage,
    getTokenFromLocalStorage,
    removeRefreshTokenToLocalStorage,
    removeTokenToLocalStorage,
    saveRefreshTokenToLocalStorage,
    saveTokenToLocalStorage
} from '../Common/Storage/LocalStorage';
import i18n from '../Config/Localization/i18n';

class AuthStore {
    authUser = null;

    token = undefined;

    refreshToken = null;

    navigate = null;

    intendedUrl = null;

    constructor() {
        makeObservable(this, {
            authUser: observable,
            userAuthenticated: computed,
            token: observable,
            refreshToken: observable,
            logout: action,
            login: action,
            setUserEmailVerified: action,
            setAuthUser: action,
            autoLogin: action,
            isUser: computed,
            isAdmin: computed,
            isSuperAdmin: computed,
            authUserIsNotLoaded: computed
        });

        // eslint-disable-next-line no-use-before-define
        reaction(() => this.token, performLoginCheck.bind(this));
        reaction(
            () => this.refreshToken,
            (refreshToken) => saveRefreshTokenToLocalStorage(refreshToken)
        );

        this.autoLogin();
    }

    autoLogin() {
        // this will trigger the reactions below
        // We should first set the refresh token for the flow to work properly
        this.refreshToken = getRefreshTokenFromLocalStorage();
        this.token = getTokenFromLocalStorage();
        setBearerToken(this.token);
    }

    logout() {
        this.authUser = null;
        removeTokenToLocalStorage();
        removeRefreshTokenToLocalStorage();
        setBearerToken(null);
    }

    get userAuthenticated() {
        return !!this.authUser;
    }

    async login({ email, password }, navigate, location) {
        try {
            const { token, refreshToken, success: status } = await login(email, password);
            if (status) {
                this.refreshToken = refreshToken;
                this.token = token;
                this.navigate = navigate;
                this.intendedUrl = location.state.from;
                success(i18n.t('login.YouHaveSuccessFullyLoggedIn'));
            }
        } catch (e) {
            this.logout();
        }
    }

    setUserEmailVerified() {
        this.authUser.emailConfirmed = true;
    }

    setAuthUser(user) {
        this.authUser = user;
    }

    // eslint-disable-next-line class-methods-use-this
    updateProfile(phoneNumber) {
        updateProfileRequest(phoneNumber)
            .then((response) => {
                // update authUser
                success(i18n.t('profile.ProfileWasSuccessfullyUpdated'));
            })
            .catch((e) => console.log);
    }

    // eslint-disable-next-line class-methods-use-this
    updatePassword(currentPassword, newPassword, confirmNewPassword) {
        return changePassword(currentPassword, newPassword, confirmNewPassword)
            .then((response) => {
                success(i18n.t('forms.PasswordWasSuccessfullyUpdated'));
            }) // dispatch toast message
            .catch((e) => console.log);
    }

    // eslint-disable-next-line class-methods-use-this
    forgotPassword(email) {
        return forgotPassword(email)
            .then((response) => {
                console.log(response);
                success('A confirmation email has been sent');
            })
            .catch((e) => console.log);
    }

    // eslint-disable-next-line class-methods-use-this
    resetPassword(data, token) {
        return resetPassword(data, token)
            .then((response) => {
                console.log(response);
                success('You have successfully changed your password');
            })
            .catch((e) => console.log);
    }

    verifyEmail(token) {
        return new Promise((resolve, reject) => {
            verifyMail(token)
                .then((response) => {
                    this.setUserEmailVerified();
                    resolve(response);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    sendVerificationEmail() {
        getVerificationMail()
            .then((response) => {
                console.log(response);
                success('A verification email has been sent');
            })
            .catch((e) => console.log);
    }

    requestNewToken() {
        refreshTokenRequest(this.token, this.refreshToken)
            .then(({ token, refreshToken, success }) => {
                if (success) {
                    this.refreshToken = refreshToken;
                    this.token = token;
                } else {
                    this.logout();
                }
            })
            .catch((e) => this.logout());
    }

    navigateToIntendedUrl() {
        if (this.navigate && this.intendedUrl) {
            this.navigate(this.intendedUrl);
            this.navigate = null;
            this.intendedUrl = null;
        }
    }

    get authUserIsNotLoaded() {
        return !Object.keys(this.authUser).length;
    }

    get isUser() {
        return this.authUser?.roles?.includes('User');
    }

    get isSuperUser() {
        return this.authUser?.roles?.includes('Superuser');
    }

    get isAdmin() {
        return this.authUser?.roles?.includes('Admin');
    }

    get isSuperAdmin() {
        return this.authUser?.roles?.includes('Superadmin');
    }
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => <AuthStoreContext.Provider value={store}>{children}</AuthStoreContext.Provider>;
export const useAuthStore = () => useContext(AuthStoreContext);

function detectTokenValidity() {
    const sessionToken = getTokenFromLocalStorage();
    if (!sessionToken) return this.logout();

    try {
        const payload = decodeJwtToken(sessionToken);
        if (payload.exp < new Date().getTime() / 1000 && this.refreshToken) return this.updateToken();

        return setBearerToken(sessionToken);
    } catch (e) {
        return this.logout();
    }
}

// eslint-disable-next-line consistent-return
function performLoginCheck(token) {
    if (!token) return this.logout();

    detectTokenValidity.bind(this)();

    try {
        const payload = decodeJwtToken(token);
        if (payload.exp < new Date().getTime() / 1000 && this.refreshToken) return this.requestNewToken();
        setBearerToken(token);

        getProfile()
            .then(({ user }) => {
                this.setAuthUser(user);
                saveTokenToLocalStorage(token);
                this.navigateToIntendedUrl();
            })
            .catch((e) => this.logout());
    } catch (e) {
        this.logout();
    }
}
