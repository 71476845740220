import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import Authenticated from './Authenticated';
import Guest from './Guest';
import GuestRoutes from '../Routes/GuestRoutes';
import AuthenticatedRoutes from '../Routes/AuthenticatedRoutes';
import { useAuthStore } from '../Stores/AuthStore';

const AppContent = () => {
    const authStore = useAuthStore();

    return (
        <BrowserRouter>
            {authStore.userAuthenticated ? (
                <Authenticated>
                    <AuthenticatedRoutes />
                </Authenticated>
            ) : (
                <Guest>
                    <GuestRoutes />
                </Guest>
            )}
        </BrowserRouter>
    );
};

export default observer(AppContent);
