import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useThemeStore } from '../../Stores/ThemeStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import el from '../../assets/images/icons/Languages/el.svg';
import en from '../../assets/images/icons/Languages/en.svg';

function LanguageSwitcher() {
    const themeStore = useThemeStore();
    const { t } = useTranslation();

    const handleChange = (event) => {
        themeStore.changeLocale(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 55, m: 2, maxWidth: 55 }}>
            <FormControl fullWidth variant="standard">
                <InputLabel id="language-switcher">{t('locale.Language')}</InputLabel>
                <Select labelId="language-switcher" id="language-switcher" value={themeStore.locale} onChange={handleChange}>
                    <MenuItem value="en">
                        <img src={en} alt="" width="30" />
                    </MenuItem>
                    <MenuItem value="el">
                        <img src={el} alt="" width="30" />
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default observer(LanguageSwitcher);
