import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage } from '../constant';

import enTranslations from './locales/en/translation.json';
import elTranslations from './locales/el/translation.json';

export const resources = {
    en: { translation: enTranslations },
    el: { translation: elTranslations },
};

export const languages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    // debug: true,
});

export default i18n;
