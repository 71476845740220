import * as CryptoJS from 'crypto-js';
import { encryptionKey } from '../../Config/constant';
import jwtDecode from 'jwt-decode';

export function encrypt(value) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), encryptionKey).toString();
}

export function decrypt(value) {
    if (!value) {
        return null;
    }
    const decryptedValue = CryptoJS.AES.decrypt(value, encryptionKey).toString(CryptoJS.enc.Utf8);
    const decryptedValueLength = decryptedValue?.length;
    const parsedValue = decryptedValueLength === 0 ? null : JSON.parse(decryptedValue);
    return parsedValue;
}

export function decodeJwtToken(token) {
    return jwtDecode(token);
}
