import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Loadable from '../Components/Theme/Loadable';
import { lazy } from 'react';

const LoginPage = Loadable(lazy(() => import('../Pages/Guest/LoginPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('../Pages/Guest/ForgotPasswordPage')));
const ResetPasswordPage = Loadable(lazy(() => import('../Pages/Guest/ResetPasswordPage')));

const GuestRoutes = () => {
    const location = useLocation();

    return (
        <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route exact path="/reset-password/:token" element={<ResetPasswordPage />} />

            <Route path="*" element={<Navigate to="/" state={{ from: location }} />} />
        </Routes>
    );
};

export default GuestRoutes;
