import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy, useEffect } from 'react';
import Loadable from '../Components/Theme/Loadable';
import { useAuthStore } from '../Stores/AuthStore';
import { observer } from 'mobx-react';

const Dashboard = Loadable(lazy(() => import('../Pages/Authenticated/Dashboard')));
const Profile = Loadable(lazy(() => import('../Pages/Authenticated/Profile/Profile')));
const VerifyEmail = Loadable(lazy(() => import('../Pages/Authenticated/Profile/VerifyEmail')));

const Users = Loadable(lazy(() => import('../Pages/Authenticated/Users/UsersList')));
const UsersCreate = Loadable(lazy(() => import('../Pages/Authenticated/Users/CreateUsers')));
const EditUsers = Loadable(lazy(() => import('../Pages/Authenticated/Users/EditUsers')));

const Areas = Loadable(lazy(() => import('../Pages/Authenticated/Areas/AreasList')));
const AreasCreate = Loadable(lazy(() => import('../Pages/Authenticated/Areas/AreasCreate')));
const AreasEdit = Loadable(lazy(() => import('../Pages/Authenticated/Areas/AreasEdit')));
const AreasDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/Areas/AreasDetailsTabs')));

const Categories = Loadable(lazy(() => import('../Pages/Authenticated/Categories/CategoriesList')));
const CategoriesCreate = Loadable(lazy(() => import('../Pages/Authenticated/Categories/CategoriesCreate')));
const CategoriesEdit = Loadable(lazy(() => import('../Pages/Authenticated/Categories/CategoriesEdit')));
const CategoriesDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/Categories/CategoriesDetailsTabs')));

const SubCategories = Loadable(lazy(() => import('../Pages/Authenticated/SubCategories/SubCategoriesList')));
const SubCategoriesCreate = Loadable(lazy(() => import('../Pages/Authenticated/SubCategories/SubCategoriesCreate')));
const SubCategoriesEdit = Loadable(lazy(() => import('../Pages/Authenticated/SubCategories/SubCategoriesEdit')));
// const SubCategoriesDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/SubCategories/SubCategoriesDetailsTabs')));

const Pois = Loadable(lazy(() => import('../Pages/Authenticated/Pois/PoistList')));
const PoisCreate = Loadable(lazy(() => import('../Pages/Authenticated/Pois/PoisCreate')));
const PoisEdit = Loadable(lazy(() => import('../Pages/Authenticated/Pois/PoisEdit')));
const PoisDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/Pois/PoisDetailsTabs')));

const RoutesList = Loadable(lazy(() => import('../Pages/Authenticated/Route/RouteList')));
const RoutesCreate = Loadable(lazy(() => import('../Pages/Authenticated/Route/RouteCreate')));
const RoutesEdit = Loadable(lazy(() => import('../Pages/Authenticated/Route/RouteEdit')));
const RoutesDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/Route/RouteDetailsTabs')));

const AuthenticatedRoutes = () => {
    const authStore = useAuthStore();

    useEffect(() => {
        authStore.autoLogin();
    }, [authStore]);

    return (
        <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/verify-email/:token" element={<VerifyEmail />} />

            <Route exact path="/areas" element={<Areas />} />
            <Route exact path="/areas/create" element={<AreasCreate />} />
            <Route exact path="/areas/:id" element={<AreasDetailsTabs />} />
            <Route exact path="/areas/:id/edit" element={<AreasEdit />} />

            <Route exact path="/categories" element={<Categories />} />
            <Route exact path="/categories/create" element={<CategoriesCreate />} />
            <Route exact path="/categories/:id" element={<CategoriesDetailsTabs />} />
            <Route exact path="/categories/:id/edit" element={<CategoriesEdit />} />

            <Route exact path="/subCategories" element={<SubCategories />} />
            <Route exact path="/subCategories/create" element={<SubCategoriesCreate />} />
            {/* <Route exact path="/subCategories/:id" element={<SubCategoriesDetailsTabs />} /> */}
            <Route exact path="/subCategories/:id/edit" element={<SubCategoriesEdit />} />

            <Route exact path="/pois" element={<Pois />} />
            <Route exact path="/pois/create" element={<PoisCreate />} />
            <Route exact path="/pois/:id" element={<PoisDetailsTabs />} />
            <Route exact path="/pois/:id/edit" element={<PoisEdit />} />

            <Route exact path="/routes" element={<RoutesList />} />
            <Route exact path="/routes/create" element={<RoutesCreate />} />
            <Route exact path="/routes/:id" element={<RoutesDetailsTabs />} />
            <Route exact path="/routes/:id/edit" element={<RoutesEdit />} />

            <Route exact path="/users" element={<Users />} />
            <Route exact path="/users/create" element={<UsersCreate />} />
            <Route exact path="/users/:id/edit" element={<EditUsers />} />

            {(authStore.isUser || authStore.authUserIsNotLoaded) && <></>}

            {(authStore.isSuperUser || authStore.authUserIsNotLoaded) && <></>}

            {(authStore.isAdmin || authStore.authUserIsNotLoaded) && <></>}

            {(authStore.isSuperAdmin || authStore.authUserIsNotLoaded) && <></>}

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
export default observer(AuthenticatedRoutes);
