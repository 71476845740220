// assets

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const superuser = {
    id: 'superuser',
    type: 'group',
    children: [],
};

export default superuser;
