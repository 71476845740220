// assets

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const superadmin = {
    id: 'superadmin',
    type: 'group',
    children: [],
};

export default superadmin;
