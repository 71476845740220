import dashboard from './dashboard';
import user from './user';
import superuser from './superuser';
import admin from './admin';
import superadmin from './superadmin';

// ==============================|| MENU ITEMS ||============================== //

// eslint-disable-next-line import/prefer-default-export
export function menuItems(authStore) {
    const items = [dashboard];

    if (authStore.isUser) items.push(user);
    if (authStore.isSuperUser) items.push(superuser);
    if (authStore.isAdmin) items.push(admin);
    if (authStore.isSuperAdmin) items.push(superadmin);

    return { items };
}
