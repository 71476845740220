import { decrypt, encrypt } from '../Encryption/Encryption';
// eslint-disable-next-line import/named
import { refreshTokenKey, tokenKey, localeKey } from '../../Config/constant';

export function getTokenFromLocalStorage() {
    return decrypt(localStorage.getItem(tokenKey));
}

export function getRefreshTokenFromLocalStorage() {
    return decrypt(localStorage.getItem(refreshTokenKey));
}

export function getLocaleFromLocalStorage() {
    return decrypt(localStorage.getItem(localeKey));
}

export function saveTokenToLocalStorage(token) {
    localStorage.setItem(tokenKey, encrypt(token));
}

export function saveRefreshTokenToLocalStorage(refreshToken) {
    localStorage.setItem(refreshTokenKey, encrypt(refreshToken));
}

export function saveLocaleToLocalStorage(locale) {
    localStorage.setItem(localeKey, encrypt(locale));
}

export function removeTokenToLocalStorage() {
    localStorage.removeItem(tokenKey);
}

export function removeRefreshTokenToLocalStorage() {
    localStorage.removeItem(refreshTokenKey);
}

export function removeLocaleFromLocalStorage() {
    localStorage.removeItem(localeKey);
}
