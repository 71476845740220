// assets
import { IconDashboard } from '@tabler/icons';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import InterestsIcon from '@mui/icons-material/Interests';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AltRouteIcon from '@mui/icons-material/AltRoute';

// constant
const icons = { IconDashboard, LocationOnIcon, CategoryIcon, ClassIcon, InterestsIcon, GroupAddIcon, AltRouteIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'misc.Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: 'areas',
            title: 'areas.Areas',
            type: 'collapse',
            icon: icons.LocationOnIcon,
            children: [
                {
                    id: 'areas-list',
                    title: 'areas.AreasList',
                    type: 'item',
                    url: '/areas',
                    breadcrumbs: false,
                },
                {
                    id: 'areas-create',
                    title: 'areas.CreateNewArea',
                    type: 'item',
                    url: '/areas/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
        {
            id: 'categories',
            title: 'categories.Categories',
            type: 'collapse',
            icon: icons.CategoryIcon,
            children: [
                {
                    id: 'categories-list',
                    title: 'categories.CategoriesList',
                    type: 'item',
                    url: '/categories',
                    breadcrumbs: false,
                },
                {
                    id: 'categories-create',
                    title: 'categories.CreateNewCategory',
                    type: 'item',
                    url: '/categories/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
        {
            id: 'subCategories',
            title: 'subCategories.SubCategories',
            type: 'collapse',
            icon: icons.ClassIcon,
            children: [
                {
                    id: 'subCategories-list',
                    title: 'subCategories.SubCategoriesList',
                    type: 'item',
                    url: '/subCategories',
                    breadcrumbs: false,
                },
                {
                    id: 'subCategories-create',
                    title: 'subCategories.CreateNewSubCategory',
                    type: 'item',
                    url: '/subCategories/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
        {
            id: 'pois',
            title: 'pois.Pois',
            type: 'collapse',
            icon: icons.InterestsIcon,
            children: [
                {
                    id: 'pois-list',
                    title: 'pois.PoisList',
                    type: 'item',
                    url: '/pois',
                    breadcrumbs: false,
                },
                {
                    id: 'pois-create',
                    title: 'pois.CreateNewPoi',
                    type: 'item',
                    url: '/pois/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
        {
            id: 'routes',
            title: 'routes.Routes',
            type: 'collapse',
            icon: icons.AltRouteIcon,
            children: [
                {
                    id: 'routes-list',
                    title: 'routes.RoutesList',
                    type: 'item',
                    url: '/routes',
                    breadcrumbs: false,
                },
                {
                    id: 'routes-create',
                    title: 'routes.CreateNewRoute',
                    type: 'item',
                    url: '/routes/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
        {
            id: 'users',
            title: 'users.Users',
            type: 'collapse',
            icon: icons.GroupAddIcon,
            children: [
                {
                    id: 'users-list',
                    title: 'users.UsersList',
                    type: 'item',
                    url: '/users',
                    breadcrumbs: false,
                },
                {
                    id: 'users-create',
                    title: 'users.CreateNewUser',
                    type: 'item',
                    url: '/users/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
