// assets

// constant
const icons = {};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const user = {
    id: 'users',
    type: 'group',
    children: [],
};

export default user;
