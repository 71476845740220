// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'users.Users',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'users-list',
                    title: 'users.UsersList',
                    type: 'item',
                    url: '/users',
                    breadcrumbs: false,
                },
                {
                    id: 'users-create',
                    title: 'users.CreateNewUser',
                    type: 'item',
                    url: '/users/create',
                    breadcrumbs: false,
                },
            ],
            breadcrumbs: false,
        },
    ],
};

export default admin;
