import { requests } from '../Common/HttpClient/HttpClient';

const { post, get, patch } = requests;

export function login(email, password) {
    return post('/api/Auth/login', { email, password });
}

export function resetPassword(data, token) {
    return post('/api/Auth/resetPassword', { ...data, token });
}

export function getProfile() {
    return get('/api/Auth');
}

export function getVerificationMail() {
    return get('/api/Auth/sendVerifiacationMail');
}

export function verifyMail(token) {
    return post('/api/Auth/verifyMail', { token });
}

export function updateProfile(phoneNumber) {
    return patch('/api/Auth', { phoneNumber: `${phoneNumber}` });
}

export function changePassword(currentPassword, newPassword, confirmNewPassword) {
    return patch('/api/Auth/updatePassword', { currentPassword, newPassword, confirmNewPassword });
}

export function forgotPassword(email) {
    return post('/api/Auth/forgotPassword', { email: `${email}` });
}

export function refreshToken(token, refreshToken) {
    return post('/api/Auth/refreshToken', { token, refreshToken });
}
