import { toast } from 'react-toastify';

export function success(message) {
    toast.success(message, {
        position: 'top-right',
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
}

export function warning(message) {
    toast.warning(message, {
        position: 'top-right',
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
}

export function error(message) {
    toast.error(message, {
        position: 'top-right',
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
}
